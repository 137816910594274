import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import {extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import Vue from 'vue';

export const validateFolderName = (value) => /^[\w\-\s]+$/.test(value);

extend('folder', {
    validate: validateFolderName,
    message: 'This field must only include letters, numbers, -, _, and spaces',
});

extend('required', {
    ...required,
    message: 'This field is required',
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
