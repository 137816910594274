export default [
    {
        path: '/:type/:id',
        component: () => import(/* webpackChunkName: "FSBase" */ '@/views/FSBase.vue'),
        children: [
            {
                path: '',
                name: 'folder',
                component: () => import(/* webpackChunkName: "FSFolder" */ '@/views/FSFolder.vue'),
            },
            {
                path: 'activity',
                name: 'activity',
                component: () => import(/* webpackChunkName: "FSActivity" */ '@/views/FSActivity.vue'),
                meta: {
                    title: 'Activity Log',
                },
            },
        ],
    },
    // Fallback
    {
        path: '*',
        component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
        meta: {
            title: 'Not Found',
        },
    },
];
