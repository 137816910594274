<template>
    <div id="app">
        <RouterView
            v-if="permissionsHaveLoaded"
            :mode="mode"
            @toggle-mode="toggleMode($event)"
        />
        <div
            v-else
            class="row"
        >
            <KCLoadingIndicator
                class="col-md-6 mx-auto loading"
                :loading="true"
                :stacked="true"
            >
                <template #loading-text>
                    Loading...
                </template>
            </KCLoadingIndicator>
        </div>
    </div>
</template>

<script>
    import KCLoadingIndicator from '@imt/vue-kit-car/src/components/KCLoadingIndicator.vue';
    import modeMixin from '@imt/vue-kit-car/src/mixins/modes';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import {mapState} from 'vuex';

    export default {
        name: 'App',
        components: {KCLoadingIndicator},
        mixins: [
            authMixin,
            modeMixin,
        ],
        computed: {
            permissionsHaveLoaded() {
                return !!this.user.id;
            },
            ...mapState('toolbox', [
                'user',
            ]),
        }
    };
</script>

<style lang="sass">
    @import '~@imt/vue-kit-car/src/sass/kit-car'

    .loading
        margin-top: 15%!important
</style>
