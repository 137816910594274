import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueAnalytics from 'vue-analytics';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/plugins/font-awesome';
import '@/plugins/lightbox';
import '@/plugins/sentry';
import '@/plugins/vee-validate';

Vue.use(BootstrapVue);
Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_ANALYTICS_ID,
    debug: {enabled: false}
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    created() {
        store.dispatch('toolbox/fetchUser');
    },
    render: h => h(App)
}).$mount('#app');
