import vueActivity from '@imt/vue-activity/src/store';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth, getCookieValue} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const formatChildren = item => {
    const formatChild = (child, propName) => {
        let regex = new RegExp(`_([a-z0-9])`, 'g'),
            camelName = propName.replace(regex, g => g[1].toUpperCase());
        child[camelName] = child[propName];

        if (regex.test(propName)) {
            delete child[propName];
        }
    };

    Object.keys(item).forEach((propName) => {
        formatChild(item, propName);
    });

    item.children?.forEach(child => {
        Object.keys(child).forEach((propName) => formatChild(child, propName));

        if (child.children) {
            formatChildren(child);
        }
    });

    return item;
};

export const authedAxios = axiosWithAuth();

export let getters = {
    getIcon: () => (mimeType) => {
        return {
            'application/msword': 'file-alt',
            'application/vnd.ms-excel': 'file-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-alt',
            'application/pdf': 'file-pdf',
            'audio/mpeg': 'file-audio',
            'text/csv': 'file-csv',
            'text/plain': 'file-code',
        }[mimeType] || 'file';
    },
    getValidFolderTypes: () => {
        return [
            'claim',
            'policy',
        ];
    },
    isImage: () => (mimeType) => {
        return ['image/jpeg', 'image/png'].includes(mimeType);
    },
};

export let actions = {
    async archiveFolder(_, id) {
        await authedAxios.delete(`folders/${id}/`);
    },
    async createFolder(_, folder) {
        const response = await authedAxios.post(`folders/`, utils.dataFormatter.serialize({stuff: {...folder, type: 'Folder'}}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async downloadZip(_, {folder, files}) {
        const response = await authedAxios.post(`download/`, {folder, files}, {
                headers: {'Content-Type': 'application/json'},
                responseType: 'blob',
            }),
            link = document.createElement('a');

        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', 'download.zip');
        document.body.appendChild(link);
        link.click();
    },
    async fetchFiles({commit, state}, {folderId, queryString = '', search = false}) {
        let url = `files/?include=created_by${search ? `&folder=${folderId}` : `&filter[folder_id]=${folderId}`}`;
        url += `&page[offset]=${state[search ? 'searchResults' : 'files'].length}`;
        url += `${search ? `&search=${state.query}` : ''}&${queryString}`;

        const response = await authedAxios.get(url),
            field = search ? 'searchResults' : 'files';

        commit('SET_DATA', {field, data: [
            ...state[field], ...utils.dataFormatter.deserialize(response.data),
        ]});
    },
    async fetchFolders({commit}, {id, includeChildren = false}) {
        const response = await authedAxios.get(`folders/${id}/${includeChildren ? '?include=children' : ''}`),
            data = {
                folder: formatChildren(response.data.data.folder),
                folders: response.data.data.folders,
                folderTree: formatChildren(response.data.data.tree),
            };

        Object.keys(data).forEach(key => commit('SET_DATA', {field: key, data: data[key]}));
    },
    async moveFiles(_, {action = 'move', folder, files}) {
        await authedAxios.post(`files/${action}/`, utils.dataFormatter.serialize({stuff: {folder, files, type: 'File'}}));
    },
    async saveFile(_, file) {
        const response = await authedAxios.patch(`files/${file.id}/`, utils.dataFormatter.serialize({stuff: file}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveFolder(_, folder) {
        const response = await authedAxios.patch(`folders/${folder.id}/`, utils.dataFormatter.serialize({stuff: folder}));

        return utils.dataFormatter.deserialize(response.data);
    },
    async uploadFile({state}, {data}) {
        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('description', data.description || '');
        formData.append('folder_id', state.folder.id);
        formData.append('mime_type', data.file.type);
        formData.append('filename', data.file.name);
        let response = await authedAxios.post('files/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': getCookieValue(),
            }
        });

        return response.data;
    },
};

export let mutations = {
    REMOVE_FILES(state, fileIds) {
        state.files = state.files.filter(file => !fileIds.includes(file.id));
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
};

export let state = {
    files: [],
    folder: {},
    folderEditing: null,
    folders: [],
    folderTree: {},
    policyData: {},
    query: null,
    searchResults: [],
};

export default new Vuex.Store({
    modules: {
        toolbox,
        vueActivity,
    },
    state,
    getters,
    mutations,
    actions,
});
