import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faFile,
    faFileAlt,
    faFileAudio,
    faFileCode,
    faFileCsv,
    faFileExcel,
    faFilePdf,
} from '@fortawesome/pro-light-svg-icons';
import {
    faMoon,
    faSun,
    faCopy,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faAngleRight,
    faArrowToBottom,
    faArrowUp,
    faCaretDown,
    faEye,
    faFolder,
    faInfoCircle,
    faLink,
    faPen,
    faPlus,
    faSave,
    faSearch,
    faTrash,
    faCloudUploadAlt,
    faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faAngleRight,
    faArrowToBottom,
    faArrowUp,
    faCaretDown,
    faCopy,
    faEye,
    faFile,
    faFileAlt,
    faFileAudio,
    faFileCode,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faFolder,
    faInfoCircle,
    faLink,
    faMoon,
    faPen,
    faPlus,
    faSave,
    faSearch,
    faSun,
    faTrash,
    faCloudUploadAlt,
    faTimesCircle,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
